@tailwind base;
@tailwind components;
@tailwind utilities;

.app-body {
  min-width: 100vw;
  max-width: 100vw;
  min-height: 100vh;
  max-height: 100vh;
}

html {
  overflow-y: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
li {
  font-family: "Rubik", sans-serif;
}

.text-gradient {
  background: linear-gradient(to right top, #161632, #5fb1bf);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #16163280;
  z-index: 99;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 10%;
  z-index: 100;
  width: max-content;
}

.dropdown {
  position: relative;
  display: inline-block;
  top: 0;
  left: 10%;
}

.centered-popup {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: fit-content;
  height: fit-content;
}

.centered-input-placeholder::placeholder {
  text-align: center;
}

.centered-input-placeholder:focus {
  text-align: center;
}

.centered-input-placeholder:active {
  text-align: center;
}

.centered-input-placeholder {
  text-align: center;
}

.fadeIn {
  animation: fadeIn 0.2s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.no-select-text {
  user-select: none;
}

.overflow-wrap {
  overflow-wrap: anywhere;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.8);
  padding: 20px;
  font-size: 30px;
  text-align: center;
}

.display-none {
  display: none;
}

.id-tooltip #id-tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: theme("colors.darkBlue");
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  display: block;
  position: fixed;
  overflow: hidden;
  top: 0px;
  left: 0px;
  z-index: 1;
}

.id-tooltip:hover #id-tooltiptext {
  visibility: visible;
}

.url-tooltip #url-tooltiptext {
  visibility: hidden;
  width: 120px;
  background: #161632;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  display: block;
  position: fixed;
  overflow: hidden;
  top: 0px;
  left: 0px;
  z-index: 1;
}

.url-tooltip:hover #url-tooltiptext {
  visibility: visible;
}
